import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { usePrismic } from '../context/PrismicContext'
import { useAuthContext } from '../context/AuthContext'
import { MagentoHistory } from '../services/magento'
import { QService } from '../services/q-services'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { LicenseInfo } from '@mui/x-license-pro'
import { Container, PageHeader, Seo } from '../components'
import { Tooltip } from '@mui/material'

LicenseInfo.setLicenseKey(
  '1f972e92138c97fff68b29aaaa534075Tz02NDkxNyxFPTE3MTM2MzQ1NDUzNTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const options = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
}

const LoadingSpinner = styled(Icon)`
  color: ${props => props.theme.colors.turquoiseGreen};
`

const LoadingText = styled.span`
  color: ${props => props.theme.colors.dimGray};
  margin-top: 1rem;
`

const OrderHistory = () => {
  const { isAuthenticated, isAuthLoading } = useAuthContext()
  const [mageOrderData, setMageOrderData] = useState([])
  const [qOrderData, setQOrderData] = useState([])
  const [qLoaded, setQloaded] = useState(false)
  const [mageShipmentData, setMageShipmentData] = useState([])
  const [shipmentDataLoaded, setShipmentDataLoaded] = useState(false)
  const [mageLoaded, setMageloaded] = useState(false)
  const [combinedOrderData, setCombinedOrderData] = useState([])
  const [loading, setLoading] = useState(false)

  const {
    prismicData: {
      prismicOrderHistoryPage: {
        order_date,
        canceled,
        type,
        quantity,
        product,
        pv,
        tracking,
        subscription,
        standard,
        loading_history,
        subtotal,
        tax,
        shipping,
        total,
        title,
        track_my_shipment,
        no_orders_found,
      },
    },
  } = usePrismic()
  const columns = [
    { field: 'orderDate', headerName: order_date[0].text },
    {
      field: 'cancelled',
      headerName: canceled[0].text,
      valueFormatter: ({ value }) => {
        if (value == false) {
          return 'No'
        }
        return 'Yes'
      },
    },
    { field: 'type', headerName: type[0].text },
    { field: 'quantity', headerName: quantity[0].text },
    { field: 'product', headerName: product[0].text },
    { field: 'pv', headerName: pv[0].text },
    {
      field: 'subtotal',
      headerName: subtotal[0].text,
      valueFormatter: ({ value }) => {
        if (!value) return '-'
        return `$${value.toFixed(2)}`
      },
    },
    {
      field: 'tax',
      headerName: tax[0].text,
      valueFormatter: ({ value }) => {
        if (!value) return '-'
        return `$${value.toFixed(2)}`
      },
    },
    {
      field: 'shipping',
      headerName: shipping[0].text,
      valueFormatter: ({ value }) => {
        if (!value) return '-'
        return `$${value.toFixed(2)}`
      },
    },
    {
      field: 'total',
      headerName: total[0].text,
      valueFormatter: ({ value }) => {
        if (!value) return '-'
        return `$${value.toFixed(2)}`
      },
    },
    {
      field: 'tracking',
      headerName: tracking[0].text,
      width: 150,
      renderCell: params =>
        params.value && (
          <Tooltip title="View Tracking Information">
            <a href={params.value} target="_blank" rel="noopener noreferrer">
              {track_my_shipment[0].text}
            </a>
          </Tooltip>
        ),
    },
  ]

  useEffect(() => {
    setLoading(true)
    if (isAuthLoading) return
    if (isAuthenticated) {
      MagentoHistory.User.getCustomerOrders().then(({ customerOrders }) => {
        if (customerOrders.items.length == 0) {
          setMageloaded(true)
        }
        setMageOrderData(customerOrders.items)
      })
      QService.User.getMyOrders().then(qOrders => {
        if (qOrders.length == 0) {
          setQloaded(true)
        }
        setQOrderData(qOrders)
      })
      MagentoHistory.User.orderShipmentHistory().then(
        ({
          customer: {
            orders: { items },
          },
        }) => {
          if (items.length == 0) {
            setShipmentDataLoaded(true)
          }
          setMageShipmentData(items)
        }
      )
    }
  }, [isAuthenticated, isAuthLoading])

  useEffect(() => {
    if (mageOrderData.length > 0 && !mageLoaded) {
      setMageloaded(true)
    }
    if (qOrderData.length > 0 && !qLoaded) {
      setQloaded(true)
    }
    if (mageShipmentData.length > 0 && !shipmentDataLoaded) {
      setShipmentDataLoaded(true)
    }
  }, [mageOrderData, qOrderData, mageShipmentData])

  const fixupType = type => {
    if (type == 'AUTO_SHIP') {
      return subscription[0].text
    }
    if (type == 'STANDARD') {
      return standard[0].text
    }
    return type
  }

  const buildTracking = shipments => {
    const tracking = shipments
      ?.map(ship => ship.tracking ?? [])
      .reduce((arr, curVal) => {
        return arr.concat(curVal)
      }, [])
    for (const track of tracking) {
      let shipUrl = `https://google.com/search?q=${track.number}`
      switch (track.carrier.toLowerCase()) {
        case 'dhl':
          shipUrl = `https://www.dhl.com/en/express/tracking.html?AWB=${track.number}&brand=DHL`
          break
        case 'ups':
          shipUrl = `https://www.ups.com/track?trackingNumber=${track.number}`
          break
        case 'usps':
          shipUrl = `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${track.number}`
          break
        case 'fedex':
          shipUrl = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${track.number}`
          break
        case 'aupost':
          shipUrl = `https://auspost.com.au/mypost/track/#/details/${track.number}`
          break
        case 'landmark':
          shipUrl = `https://track.landmarkglobal.com/?search=${track.number}`
          break
      }
      track.shipUrl = shipUrl
    }
    return tracking
  }

  useEffect(() => {
    if (qLoaded && mageLoaded && shipmentDataLoaded) {
      let combinedData = []
      for (let i = 0; i < qOrderData.length; i++) {
        let order = qOrderData[i]
        const mageOrd = mageOrderData.find(mOrd => {
          return mOrd.id == order.magentoOrderId
        })
        const shipment =
          mageOrd !== undefined
            ? mageShipmentData.find(shipInfo => {
                return shipInfo.number == mageOrd.order_number
              })
            : undefined
        const prodNames = order.orderDetails.map(det => {
          return det.productName
        })
        const trackingArray = buildTracking(shipment?.shipments ?? [])
        const trackingUrl =
          trackingArray.length > 0 ? trackingArray[0].shipUrl : ''
        const orderDate = new Date(order.dateOrder).toLocaleDateString(
          'en-US',
          options
        )
        combinedData.push({
          id: order.orderId,
          orderDate: orderDate,
          cancelled: order.datePaidFor === null,
          type: fixupType(order.type),
          quantity: order.orderDetails.reduce(
            (acc, val) => acc + val.quantity,
            0
          ),
          product: prodNames.join(),
          pv: order.pv,
          subtotal: order.subTotal,
          tax: order.taxTotal,
          shipping: order.shippingTotal,
          total: order.grandTotal,
          tracking: trackingUrl,
          path: [order.orderId],
        })
        for (let j = 0; j < order.orderDetails.length; j++) {
          const detail = order.orderDetails[j]
          combinedData.push({
            id: detail.orderDetailId,
            orderDate: orderDate,
            quantity: detail.quantity,
            cancelled: order.datePaidFor === null,
            type: fixupType(order.type),
            product: detail.productName,
            pv: detail.pv,
            subtotal: detail.amount,
            tracking: trackingUrl,
            path: [order.orderId, detail.orderDetailId],
          })
        }
      }
      setCombinedOrderData(combinedData)
    }
  }, [qLoaded, mageLoaded, shipmentDataLoaded])

  useEffect(() => {
    if (
      combinedOrderData.length > 0 ||
      (qLoaded && mageLoaded && shipmentDataLoaded)
    ) {
      setLoading(false)
    }
  }, [combinedOrderData])

  if (loading) {
    return (
      <Container direction="column">
        <LoadingSpinner loading size="large" name="circle notched" />
        <LoadingText>{loading_history[0].text}</LoadingText>
      </Container>
    )
  }

  if (!loading && combinedOrderData.length == 0) {
    return (
      <Container direction="column">
        <LoadingText>{no_orders_found[0].text}</LoadingText>
      </Container>
    )
  }

  return (
    <>
      <PageHeader exitRoute="/">{title[0].text}</PageHeader>
      <DataGridPro
        style={{ minHeight: 300 }}
        columns={columns}
        disableChildrenSorting={true}
        disableRowSelectionOnClick={true}
        rows={combinedOrderData}
        treeData
        pagination={true}
        slots={{
          toolbar: GridToolbar,
        }}
        getTreeDataPath={row => row.path}
        density="compact"
        groupingColDef={{
          headerName: 'ID',
        }}
      />
    </>
  )
}

export default OrderHistory
